import classNames from 'classnames'
import React from 'react'
import {Tag, TagSize, TagSkin} from 'wix-ui-tpa/cssVars'
import {TagsProps} from './interfaces'
import {classes} from './tags.st.css'

export const Tags: React.FC<TagsProps> = ({className, items}) => (
  <div className={classNames(className, classes.tagList)}>
    {items.map((tag, i) => (
      <Tag className={classes.tag} key={i} tagName="div" size={TagSize.small} skin={TagSkin.light}>
        {tag.value}
      </Tag>
    ))}
  </div>
)
